import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Types
import { PageInfoProps, PublicationProps } from '../types/pages'
import { SectionBase } from '../types/sections'
import { PricingProps } from '../types/pricing'
// Components
import { Layout, Seo } from '../components/commons'
import Section from '../components/sections'
import PricingContent from '../components/pricing/PricingContent'

interface DataQuery {
  datoCmsPricing: PricingProps & {
    pageInfo: PageInfoProps
    locale: string
    meta: PublicationProps
    sections: SectionBase[]
  }
}

const Pricing: React.FC<PageProps<DataQuery>> = ({ data }) => {
  const {
    datoCmsPricing: { pageInfo, meta, locale, sections, ...pricingContent },
  } = data

  return (
    <Layout headerDark={pageInfo.headerDark}>
      <Seo slug={pageInfo.slug} publication={meta} lang={locale} content={pageInfo.seo} />
      <PricingContent {...pricingContent} />
      {sections.map((s: SectionBase) => s?.model && <Section key={s.id} sectionApiKey={s.model.apiKey} data={s} />)}
    </Layout>
  )
}

export const query = graphql`
  query PricingQuery {
    datoCmsPricing {
      locale
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      pageInfo {
        ...pageInfoFragment
      }
      ...pricingFragment
      sections {
        ...sectionLogoWallFragment
        ...sectionVideoFragment
        ...sectionPodcastFragment
      }
    }
  }
`

export default Pricing
