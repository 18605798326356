import React from 'react'
// Types
import { PricingProps } from '../../../types/pricing'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Box, Text, Button } from '../../../atoms'
import PricingPlan from '../PricingPlan'

const PricingContent: React.FC<PricingProps> = ({
  headline,
  subHeadline,
  whiteText,
  button,
  buttonLegend,
  backgroundColor,
  plans,
}) => {
  return (
    <>
      <Box
        as={'section'}
        id={'pricing-hero'}
        bg={backgroundColor?.hex || 'white'}
        pt={[140, 140, 160]}
        pb={[260, 260, 260, 360]}
      >
        <Grid>
          <Row center={'xs'}>
            <Col xs={12} md={10}>
              <Text
                as={'h1'}
                fontFamily={'hoves'}
                fontSize={[32, 40, 56]}
                fontWeight={'semi'}
                lineHeight={['40px', '48px', '64px']}
                color={whiteText ? 'white' : 'dark'}
                textAlign={'center'}
              >
                {headline}
              </Text>
            </Col>
          </Row>
          {!!subHeadline && (
            <Box mt={4}>
              <Row center={'xs'}>
                <Col xs={12} md={10} lg={8}>
                  <Text color={whiteText ? 'white' : 'dark'} textAlign={'center'}>
                    {subHeadline}
                  </Text>
                </Col>
              </Row>
            </Box>
          )}

          {button && (
            <Box mt={7}>
              <Row center={'xs'}>
                <Col xs={12} sm={4} md={3} lg={2}>
                  <Button fluid {...button} />
                </Col>
                {!!buttonLegend && (
                  <Col xs={12} sm={4} md={3} lg={2}>
                    <Box mt={[4, 0]}>
                      <Text fontSize={1} textAlign={['center', 'left']} color={'lightBlue'}>
                        Signup today for a 30 day free trial of Pro.
                      </Text>
                    </Box>
                  </Col>
                )}
              </Row>
            </Box>
          )}
        </Grid>
      </Box>
      {plans && plans.length > 0 && (
        <Box as={'section'} id={'pricing-plans'} mb={[80, 80, 120]} mt={[-160, -160, -160, -220]}>
          <Grid>
            <Row>
              {plans.map((p, i) => (
                <Col key={p.id} xs={12} sm={4}>
                  <Box position={'relative'} height={['auto', '100%']} mb={i + 1 < plans.length ? [7, 0] : 0}>
                    <Box
                      position={'absolute'}
                      left={'50%'}
                      top={'-60px'}
                      bg={p.color || 'purpleBlue'}
                      width={'100%'}
                      pt={'100%'}
                      borderRadius={'circle'}
                      opacity={0.16}
                      zIndex={0}
                      style={{ filter: 'blur(100px)', pointerEvents: 'none', transform: 'translate3d(-50%, 0,0)' }}
                    />
                    <PricingPlan {...p} />
                  </Box>
                </Col>
              ))}
            </Row>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default PricingContent
